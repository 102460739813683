import React from 'react';
import PageLayout from '../../components/Layout/Page';
import EventsShell from '../../components/Event/Shell';

const StudentCalendar = () => {
  return (
    <PageLayout title="Student Calendar">
      <EventsShell
        defaultView="calendar"
        filterFn={event => event.show_in_student_calendar}
      />
    </PageLayout>
  );
};

export default StudentCalendar;
